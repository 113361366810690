import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { NotFound404Component } from './components/errors/not-found404/not-found404.component';
import { TrackingDetailsComponent } from './components/users/customers/tracking-details/tracking-details.component';
import { PrivilegiosCustomer } from './authguard/privilegiosCustomer.guard';
import { ProveedorGuard } from './authguard/proveedor.guard';
import { ConductorGuard } from './authguard/conductor.guard';

const routes: Routes = [
  {
    path: 'login', children: [
      { path: '', component: LoginComponent },
      { path: 'conductor', component: LoginComponent }
    ]
  },
  { path: 'customer/tracking/:codigoTracking', component: TrackingDetailsComponent },
  { path: '', component: LoginComponent },
  { path: 'customer', canActivate: [PrivilegiosCustomer], loadChildren: () => import('./components/users/customers/customer.module').then(m => m.CustomersModule) },
  { path: 'proveedor', canActivate: [ProveedorGuard], loadChildren: () => import('./components/users/proveedor/proveedor.module').then(m => m.ProveedorModule) },
  { path: 'conductor', canActivate: [ConductorGuard], loadChildren: () => import('./components/users/conductor/conductor.module').then(m => m.ConductorModule) },
  { path: '**', component: NotFound404Component }
  //{ path: 'terminosycondiciones', component: TerminosYCondicionesComponent },
  /*{ path: '', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule) },
  {
    path: 'customer', canActivate: [UserGuard], children: [
      { path: '', component: CustomerIndexComponent },
      {
        path: 'cotizaciones', canActivate: [PrivilegiosCustomer], data: { privilegio: "Cotizacion" }, children: [
          { path: 'aereo', component: CotizacionAereaComponent },
          { path: 'maritimo', component: CotizacionMaritimoComponent },
          { path: 'terrestre', component: CotizacionTerrestreComponent },
          { path: 'consulta/:idArea', component: ListCotizacionComponent },
        ]
      },
      {
        path: 'tarifas', canActivate: [PrivilegiosCustomer], data: { privilegio: "Tarifa" }, children: [
          {
            path: 'aereo', children: [
              { path: '', component: TarifaAereoComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          },
          {
            path: 'maritimo', children: [
              { path: '', component: TarifaMaritimoComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          },
          {
            path: 'terrestre', children: [
              { path: '', component: TarifaTerrestreComponent },
              // { path: 'listado', component: ListadoTarifasComponent },
              { path: 'list', component: ListTarifaComponent }
            ]
          }
        ]
      },
      { path: 'miPerfil', component: AjustesComponent },
      { path: 'consulta/:idArea', component: ListShipmentComponent },
      {
        path: 'shipment', canActivate: [PrivilegiosCustomer], data: { privilegio: "Booking" }, children: [
          { path: '', component: CustomerIndexComponent },
          { path: 'aereo', component: AereoShipmentComponent },
          { path: 'maritimo', component: MaritimoShipmentComponent },
          { path: 'terrestre', component: TerrestreShipmentComponent },
          { path: 'documents', component: ShippingFilesComponent },
          { path: 'tracking', canActivate: [PrivilegiosCustomer], data: { privilegio: "Tracking" }, component: ShippingTrackingComponent }
        ]
      },
      { path: 'facturacion', canActivate: [PrivilegiosCustomer], data: { privilegio: "Facturacion" }, component: FacturacionComponent }
    ]
  },
  {
    path: 'conductor', canActivate: [ConductorGuard], children: [
      { path: 'exp-pendientes', component: ConductorComponent },
      { path: 'historial', component: HistorialComponent },
      { path: 'historial/:file/download', component: PreviewDownloadComponent },
      { path: 'miPerfil', component: MiPerfilComponent }
    ]
  },
  {
    path: 'proveedor', canActivate: [ProveedorGuard], children: [
      { path: '', component: ExpedicionesComponent },
      { path: 'expedicionesActivas', component: ExpedicionesComponent },
      { path: 'conductores', component: ConductoresComponent },
      { path: 'vehiculos', component: MatriculasComponent },
      { path: 'historial', component: HistorialProveedorComponent },
      { path: 'miPerfil', component: MiPerfilComponent },
      { path: 'documentacion', component: DocumentacionProveedorComponent }
    ]
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
