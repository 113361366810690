import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PrivilegiosCustomer implements CanActivate {
  constructor(public router: Router, private translateService: TranslateService,
    private authenticationService: AuthenticationService, private toast: ToastrService) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      this.authenticationService.getTipoUsuario().subscribe(data => {
        if (data && data == "6") {
          resolve(true);
        } else {
          var Body = this.translateService.instant("No tienes permisos para entrar.");
          var Titulo = this.translateService.instant("Error");
          this.toast.error(Body, Titulo)
          this.authenticationService.limpiarLocalStorage();
          this.router.navigate(['/login']);
          reject(false);
        }
      }, () => {
        var Body = this.translateService.instant("Ha caducado la sesión, tienes que volver a iniciar sesión.");
        var Titulo = this.translateService.instant("Error");
        this.toast.error(Body, Titulo)
        this.authenticationService.limpiarLocalStorage();
        this.router.navigate(['/login']);
        reject(false);
      })
    })
  }

}
