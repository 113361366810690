import { Component, OnInit, SimpleChange } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'X-Tract-Fusion';

  constructor(public translate: TranslateService, private router: Router, private authenticationService: AuthenticationService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');

    // Forzamos la carga del archivo en.json y es.json
    translate.getTranslation('en').subscribe();
    translate.getTranslation('es').subscribe();

    translate.setDefaultLang('es');
    console.log("Hola app component");
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'es');
  }

  ngOnInit() {

  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  onRightClick() {
    return false;
  }

  onKeyDown(event) {
    if (event.keyCode === 123) { //Prevent F12
      return false;
    }
    else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
      return false;
    }
  }

}
